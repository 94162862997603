<template>
  <v-row align="center" justify="center">
    <v-col cols="12">
      <v-card elevation="0" tile>
        <v-card-title class="grey lighten-4">
          {{ $t("hr.menu.menus") }}
          <v-spacer />
          <id-button @click="onRefreshData">
            <template #icon>
              <v-icon left>mdi-refresh</v-icon>
            </template>
            {{ $t("buttons.refresh") }}
          </id-button>
        </v-card-title>
        <v-progress-linear color="primary" v-if="table.loading" indeterminate />
        <v-data-iterator
          :items="table.data"
          :loading="table.loading"
          :options.sync="table.pagination"
          :server-items-length="table.totalItems"
          :footer-props="{ 'items-per-page-options': [30, 60, 90] }"
          class="mt-3"
          row
          wrap
        >
          <template v-slot:default="{ items }">
            <v-container fluid grid-list-lg px-0 py-0>
              <v-row>
                <v-col v-for="item in items" :key="item.id" cols="12" sm="3" lg="2">
                  <v-card min-height="280">
                    <v-card-title class="grey lighten-5 px-0 py-0 text-no-wrap">
                      <v-list-item>
                        <v-list-item-avatar>
                          <v-avatar :color="item.color" size="35" class="mr-2">
                            <span class="white--text font-weight-bold">
                              {{ item.name.substring(0, 2).toUpperCase() }}
                            </span>
                          </v-avatar>
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title class="subheading"> {{ item.name }}</v-list-item-title>
                          <v-list-item-subtitle class="caption">
                            {{ $helpers.formatDate(item.date) }}</v-list-item-subtitle
                          >
                        </v-list-item-content>
                      </v-list-item>
                    </v-card-title>
                    <v-card-text style="max-height: 215px; overflow-x: hidden">
                      <ul>
                        <li v-for="(item, index) in item.items" :key="index">{{ item }}</li>
                      </ul>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-container>
          </template>
        </v-data-iterator>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
  /**
   * @author Ali Hüseyin ŞİRELİ
   * @email ali.sireli@globme.com.tr
   * @create date 2021-01-07 08:55:18
   * @modify date 2021-01-07 08:55:18
   * @desc [description]
   */
  import { MENUS } from "../query";

  export default {
    name: "Menus",
    data: vm => ({
      table: {
        loading: false,
        data: [],
        criteria: {},
        pagination: {
          rowsPerPage: 10,
          sortBy: "date",
          descending: true
        },
        totalItems: 0,
        totalPages: 0,
        selected: []
      }
    }),
    watch: {
      "table.pagination": {
        handler(options) {
          let { itemsPerPage, page } = options;
          this.table.pagination.rowsPerPage = itemsPerPage;
          this.table.pagination.page = page;
          this.fetchItems();
        },
        deep: true
      }
    },
    methods: {
      onRefreshData() {
        this.fetchItems();
      },
      async fetchItems() {
        this.table.loading = true;
        await this.$apollo
          .query({
            query: MENUS,
            variables: {
              criteria: this.table.criteria,
              pagination: this.$helpers.convertPagination(this.table.pagination)
            }
          })
          .then(({ data, errors }) => {
            if (!errors || !data.error) {
              this.table.data = data.filterWithPaginateMenus.content || [];
              this.table.totalItems = data.filterWithPaginateMenus.pageInfo.totalElements || 0;
              this.table.totalPages = data.filterWithPaginateMenus.pageInfo.totalPages || 0;
            }
          })
          .catch(e => {
            this.$helpers.showNotification(e.message);
          })
          .finally(() => (this.table.loading = false));
      }
    }
  };
</script>

<style scoped></style>
